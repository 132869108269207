<!--调拨出库-->
<template>
    <div class="AllocateOut">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调出机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.stockStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="待出库" value="0" />
                                <el-option label="已出库" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.stock.allocateOut.open')"
                >查询
            </el-button>
            <ef-export-button
                :url="'/stock/allocate/out/exportNewWay'"
                v-if="hasPrivilege('menu.stock.allocateOut.export')"
                :form="form"
                @clickButtonGetSelectedCodes="getSelectedCodes"
            ></ef-export-button>
            <!--            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.allocateOut.export')"
                >导出(旧)
            </el-button>-->
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="单号" width="180" prop="code" v-if="showColumn('code')" />
                <el-table-column label="关联调拨单号" width="180" prop="applyCode" v-if="showColumn('applyCode')" />
                <el-table-column label="调入机构" min-width="120" prop="inDeptName" v-if="showColumn('inDeptName')" />
                <el-table-column label="调出机构" min-width="120" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="单据金额"
                    width="120"
                    v-if="showColumn('money') && showInPrice()"
                    prop="money"
                    key="money"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.money | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="100"
                    v-if="showColumn('stockStatus')"
                    prop="stockStatus"
                    key="stockStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出库时间"
                    width="140"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    min-width="160"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.allocateOut.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            :disabled="scope.row.stockStatus == 1"
                            @click="handleOut(scope.row)"
                            v-if="hasPrivilege('menu.stock.allocateOut.stock')"
                            >出库
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import MoneyUtils from 'js/MoneyUtils';
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfPagination from 'components/EfPagination';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import EfExportButton from 'components/EfExportButton';

export default {
    name: 'AllocateOut',
    components: { EfEndDate, EfStartDate, EfPagination, CheckTableShowColumnDialog, EfExportButton },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                stockStatus: '',
                deptCode: '',
                page: 1,
                limit: Util.Limit,
            },
            total: 0,
            depts: [],
            tableData: [],
            url: {
                page: '/stock/allocate/out/page',
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedStoresAndRepos().then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        stockStatus(stockStatus) {
            if (typeof stockStatus == 'undefined') {
                return '';
            }
            if (stockStatus == '0') {
                return '待出库';
            }
            if (stockStatus == '1') {
                return '已出库';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney2(MoneyUtils.moneyToFour(money), 4, '.');
            } else {
                return '';
            }
        },
    },

    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        queryList() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, (rst) => {
                this.total = rst.count;
                this.tableData = rst.data;
            });
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.allocateOut.detail', ['库存管理', '调拨出库', '出库详情'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '调拨出库', '/stock/allocate/out/export', this.form, codes);
        },
        getSelectedCodes(callback) {
            const selectedCodes = (this.$refs['table'].selection || []).map((e) => e.code);
            callback(selectedCodes);
        },
        handleOut(row) {
            if (row.code) {
                this.codes = row.code;
                if (row.stockStatus != 0) {
                    this.$message.error('商品仅能出库一次');
                } else {
                    this.open();
                }
            } else if (this.$refs['table'].selection.length != 1) {
                this.$message.error('选择单据数量必须为一条');
            } else if (this.$refs['table'].selection[0].stockStatus != 0) {
                this.$message.error('商品仅能出库一次');
            } else {
                this.open();
            }
        },

        open() {
            this.$confirm('确认出库该单据么?出库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.out();
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消出库',
                    });
                });
        },

        out() {
            const _this = this;
            let _params = {};
            if (this.$refs['table'].selection[0]) {
                _params = {
                    codes: this.$refs['table'].selection[0].code,
                };
            } else {
                _params = {
                    codes: this.codes,
                };
            }
            UrlUtils.PostRemote(this, '/stock/allocate/out/out', _params, null, () => {
                _this.$message.success('出库成功');
                _this.handleQuery();
            });
        },
    },
};
</script>

<style scoped>
.AllocateOut .el-form-item {
    margin-bottom: 0;
}
</style>
